(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/adobe-target-payload-handler/assets/javascripts/helpers/data-attribute-handler.js') >= 0) return;  svs.modules.push('/components/components/adobe-target-payload-handler/assets/javascripts/helpers/data-attribute-handler.js');
"use strict";


const checkIfPropertyExists = (targetObject, property) => Object.prototype.hasOwnProperty.call(targetObject, property);
const getAllDataAttributes = element => Object.keys(element.dataset);
const getDataAttributeValue = (element, dataAttribute) => {
  if (checkIfPropertyExists(element.dataset, dataAttribute)) {
    return element.dataset[dataAttribute];
  }
};
const checkIfDataAttributesExists = (element, dataAttributes) => {
  for (let i = 0; i < dataAttributes.length; i++) {
    const dataAttribute = dataAttributes[i];
    if (!checkIfPropertyExists(element.dataset, dataAttribute)) {
      return false;
    }
  }
  return true;
};
svs.components.adobeTargetPayloadHandler.helpers.getAllDataAttributes = getAllDataAttributes;
svs.components.adobeTargetPayloadHandler.helpers.checkIfDataAttributesExists = checkIfDataAttributesExists;
svs.components.adobeTargetPayloadHandler.helpers.getDataAttributeValue = getDataAttributeValue;

 })(window);