(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/adobe-target-payload-handler/assets/javascripts/postscript.js') >= 0) return;  svs.modules.push('/components/components/adobe-target-payload-handler/assets/javascripts/postscript.js');
"use strict";

const {
  selfSendClickPayload,
  childSendClickPayload
} = svs.components.adobeTargetPayloadHandler;

$('body').on('click', '*[data-crm-mbox]', selfSendClickPayload);
$('body').on('click', '[data-crm-mbox] a, [data-crm-mbox] button', childSendClickPayload);

 })(window);