(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/adobe-target-payload-handler/assets/javascripts/click-payload.js') >= 0) return;  svs.modules.push('/components/components/adobe-target-payload-handler/assets/javascripts/click-payload.js');
"use strict";

const clickedElements = new Set();
const {
  getDataAttributeValue
} = svs.components.adobeTargetPayloadHandler.helpers;
const CRM_MBOX = 'crmMbox';
const CRM_MBOX_CLICK_PE = 'crmAnalyticsClickPayloadPe';
const CRM_MBOX_CLICK_TNTA = 'crmAnalyticsClickPayloadTnta';
const getValidClickNodes = element => {
  if (Object.prototype.hasOwnProperty.call(element.dataset, CRM_MBOX)) {
    return false;
  }
  const {
    nodeName,
    parentElement
  } = element;
  if (nodeName === 'BUTTON' || nodeName === 'A') {
    return true;
  } else if (parentElement) {
    return getValidClickNodes(parentElement);
  }
  return false;
};
const sendClickPayload = (clickEvent, widgetElement) => {
  if (!clickedElements.has(widgetElement)) {
    if (getValidClickNodes(clickEvent.target)) {
      clickedElements.add(widgetElement);
      const pe = getDataAttributeValue(widgetElement, CRM_MBOX_CLICK_PE);
      const tnta = getDataAttributeValue(widgetElement, CRM_MBOX_CLICK_TNTA);
      if (pe && tnta) {
        svs.components.analytics.sendTargetPayload({
          pe,
          tnta
        });
      }
    }
  }
};

const childSendClickPayload = clickEvent => {
  const element = clickEvent.currentTarget;
  if (element.nodeName === 'BUTTON' && $(element).closest('a')) {
    return;
  }
  const widgetElement = $(element).closest('[data-crm-mbox]');
  if (!clickedElements.has(element)) {
    if (getValidClickNodes(element)) {
      clickedElements.add(element);
      const pe = getDataAttributeValue(widgetElement[0], CRM_MBOX_CLICK_PE);
      const tnta = getDataAttributeValue(widgetElement[0], CRM_MBOX_CLICK_TNTA);
      const mbox = getDataAttributeValue(widgetElement[0], CRM_MBOX);
      if (pe && tnta) {
        svs.components.analytics.sendTargetPayload({
          pe,
          tnta,
          mbox
        }, () => {});
      }
    }
  }
};

const selfSendClickPayload = event => {
  const element = event.currentTarget;
  const {
    nodeName
  } = element;
  if (!clickedElements.has(element)) {
    if (Object.prototype.hasOwnProperty.call(element.dataset, CRM_MBOX) && nodeName === 'BUTTON' || nodeName === 'A') {
      clickedElements.add(element);
      const pe = getDataAttributeValue(element, CRM_MBOX_CLICK_PE);
      const tnta = getDataAttributeValue(element, CRM_MBOX_CLICK_TNTA);
      const mbox = getDataAttributeValue(element, CRM_MBOX);
      if (pe && tnta) {
        svs.components.analytics.sendTargetPayload({
          pe,
          tnta,
          mbox
        }, () => {});
      }
    }
  }
};
const setupClickInMbox = element => {
  element.addEventListener('click', event => {
    sendClickPayload(event, element);
  });
};
svs.components.adobeTargetPayloadHandler.setupClickInMbox = setupClickInMbox;
svs.components.adobeTargetPayloadHandler.selfSendClickPayload = selfSendClickPayload;
svs.components.adobeTargetPayloadHandler.childSendClickPayload = childSendClickPayload;

 })(window);